<template>
    <div class="hs-overview">
        <div class="app-container">
            <be-page-header :app-container="false">
                <template v-slot:default>
                    Parking Overview
                </template>
            </be-page-header>
            <vehicle-observations-filters
                simple
                :summary="filtersSummary"
                :is-loading="isLoading"
                :is-multi-select="true"
                @filter="onFilter"
            />
            <div class="hs-overview__content">
                <b-loading
                    :active="isLoading"
                    :can-cancel="false"
                    :is-full-page="false"
                />
                <div
                    v-if="hasFiltersApplied"
                    class="hs-overview__cols columns is-multiline"
                >
                    <div class="column is-12">
                        <overall-metrics :can-load-data="false" />
                    </div>
                    <div class="column is-12" v-if="hasFilter('PeakOccupancy')">
                        <occupancy :can-load-data="false" typeColor="green" />
                    </div>
                    <div class="column is-12" v-if="hasFilter('PeakVacancy')">
                        <vacancy :can-load-data="false" typeColor="blue" />
                    </div>
                    <div class="column is-12" v-if="hasFilter('AverageOccupancy')">
                        <weekday-occupancy :can-load-data="false" typeColor="purple" />
                    </div>
                    <div class="column is-12" v-if="hasFilter('AverageVacancy')">
                        <weekday-vacancy :can-load-data="false" typeColor="darkblue" />
                    </div>
                </div>
                <div
                    v-else
                    class="hs-overview__empty content has-text-centered"
                >
                    <p>Apply filters to explore the results</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';

export default {
    components: {
        VehicleObservationsFilters: () => import('@/components/local/vehicle/observations/VehicleObservationsFilters'),
        OverallMetrics: () => import('@/components/widgets/vehicle/charts/OverallMetrics'),
        Occupancy: () => import('@/components/widgets/vehicle/charts/Occupancy'),
        Vacancy: () => import('@/components/widgets/vehicle/charts/Vacancy'),
        WeekdayOccupancy: () => import('@/components/widgets/vehicle/charts/WeekdayOccupancy'),
        WeekdayVacancy: () => import('@/components/widgets/vehicle/charts/WeekdayVacancy'),
    },

    mounted() {
        this.resetFilters();
    },

    computed: {
        ...mapState('vehicle/observations/summary', ['filtersApplied', 'data']),
        ...mapGetters('vehicle/observations/summary', [
            'isLoading',
            'hasFiltersApplied',
            'filtersSummary',
            'getAppliedFilters',
        ]),
    },

    watch: {
        filtersApplied(val) {
            if (val) {
                this.loadData();
            }
        },
    },

    methods: {
        ...mapActions('vehicle/observations', ['resetFilters']),
        ...mapActions('vehicle/observations/summary', ['filter', 'loadData']),
        onFilter({ filters }) {
            this.filter({ filters });
        },

        hasFilter(filter) {
            return this.getAppliedFilters.observation.metricType
                .map((x) => x.handle)
                .includes(filter);
        },
    },
};
</script>

<style lang="scss" scoped>
.hs-overview {
    &__content {
        margin-top: 2em;
        position: relative;
    }

    &__empty {
        opacity: 0.6;
    }
}
</style>
